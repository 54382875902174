body * {
    color: white;
  }

.store-slider-group {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    align-self: center;
    gap: 15px;
    padding: 15px;
    overflow-x: auto;
    scroll-behavior: smooth;
    max-width: 94%;
    margin: 0 auto;
}

.store-card {
    min-width: 120px;
    background: #e9f5ff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 8px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    white-space: nowrap; /* Prevents text from wrapping */
}

.store-card h4 {
    margin: 0;
    font-size: 1em;
    color: #333;
}

.store-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.store-card.active {
    border: 4px solid #007bff;
    background: #e9f5ff;
}
