.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; /* For small screens to avoid clipping */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 400px;
  max-width: 90vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  text-align: center;
  margin-top: 60px; /* Adds space at the top */
}

.popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 20px; /* Additional padding for more space at the top */
}

.popup-close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 50px;
  cursor: pointer;
  background: none;
  border: none;
}

.popup-body {
  color: black;
}

.popup-body h1, .popup-body p {
  margin: 0 0 10px 0;
}

.popup-footer {
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .popup-content {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    margin-top: 50px; /* Increase space at the top for smaller screens */
  }

  .popup-header {
    font-size: 1em;
  }

  .popup-body h1 {
    font-size: 1.2em;
  }

  .popup-body p {
    font-size: 1em;
  }

  .popup-footer {
    font-size: 0.9em;
  }
}
