#footer {
    background-color: var(--black-color);
    @include padding-y(3rem, 2rem);

    .separator {
        @include margin-y(2.5rem, 2rem);
        opacity: 0.7;
    }
}

.footer_wrapper {
    grid-template-columns: 1.4fr repeat(3, 1fr);
    justify-items: center;
    gap: 2rem;

    @include devices(md) {
        grid-template-columns: 1.4fr 1fr;
        justify-items: unset;
        gap: 4rem;
    }

    @include devices(xs) {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
}

.foot_subs {
    p {
        @include margin-y(1rem, 1rem);
        font-size: 0.875rem;
        opacity: 0.9;
    }

    .btn {
        margin-top: 1rem;
    }
}

.foot_menu {
    ul {
        margin-top: 1.8rem;
        display: grid;
        gap: 1rem;

        a {
            font-size: 0.9rem;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.sub_footer_wrapper {
    @include flexbox(space-between, center);

    @include devices(md) {
        @include flexbox();
        flex-direction: column-reverse;
        gap: 2rem;
    }
}

.foot_copyright {
    font-size: 0.95rem;

    a {
        color: #eee;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}

.foot_social {
    @include flexbox();
    gap: 2rem;
    font-size: 1.2rem;
    margin-right: 4rem;
}